body, a {
    background: #000;
    color: #fff;
    font-size: 16px;
    font-family: PT Sans, arial, sans-serif;
}

.site-container {
    width: 100%;
}

.header {
    background: #100023 url(../img/header.png) center top no-repeat;
    background-size: contain;
    width: 100%;
    height: 200px;
}

.main-content {
    max-width: 875px;
    margin: 40px auto;
}
p {
    padding: 20px;
}

/* background: radial-gradient(#1a2f65, #100200); */
